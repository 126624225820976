<script>

import {BRAND_LIST} from "@/views/components/whitelabel-templates/Ganamos/const/brandList";
import {ref} from "@vue/composition-api";
import GanamosGame from "@/views/components/whitelabel-templates/Ganamos/components/sections/GanamosGame.vue";


export default {
  name: "GanamosGamesPreview",
  components: {GanamosGame},

  setup() {
    const brandList = ref(BRAND_LIST);

    return {
       brandList
    }
  }
}
</script>

<template>
  <div class="ganamos-games">
    <section v-for="(brand, index) in brandList" :key="index">

      <div class="ganamos-games-header">
        <div class="ganamos-games-header-title">
          {{ brand.brandName }}
        </div>
        <div class="ganamos-games-header-actions">
          <div class="ganamos-games-header-actions-all">
            seeAll
          </div>
        </div>
      </div>

      <div class="ganamos-games-content">
        <ganamos-game
            v-for="game in brand.gamesPreview"
            :key="game.id"
            :game="game"
        />
      </div>
    </section>

  </div>
</template>

<style scoped lang="scss">
@import "../../../common/assets/mixins.scss";

.ganamos-games {
  max-width: 100vw;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include responsive(lg) {
    width: 100%;
    padding: 15px 10px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.05rem;
    margin-bottom: 15px;
    color: var(--text-color);

    &-title {
      font-weight: 600;
      padding-bottom: 10px;
      border-bottom: 2px solid var(--secondary-color);

      @include responsive(lg) {
        font-size: 1.25rem;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 5px;

      &-all {
        display: flex;
        gap: 5px;
        align-items: center;
        color: var(--text-color);
        text-transform: uppercase;
        background: radial-gradient(circle,
            color-mix(in srgb, var(--primary-color) 100%, rgba(255, 255, 255, 0.2) 100%) 0%,
            color-mix(in srgb, var(--primary-color) 50%, rgba(255, 255, 255, 0.2) 100%) 100%);
        text-decoration: none;
        font-size: 0.7rem;
        font-weight: 500;
        padding: 5px 10px;
        cursor: pointer;
        min-width: max-content;
        border-radius: 7px;
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 15px;

    @include responsive(lg) {
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }

  &-skeleton {
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-box,
    &-line {
      border-radius: 0.25rem;
    }

    &-box {
      flex-grow: 1;
    }

    &-line {
      height: 1.5rem;
    }
  }
}

.loader {
  align-self: center;

  &-button {
    padding-bottom: 15px;
  }
}
</style>
