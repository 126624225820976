<script>
import { computed } from "@vue/composition-api";
import store from "@/store";
import "swiper/css/swiper.min.css";
import Swiper from "swiper";

export default {
  name: "GanamosHeroSwiper",
  data() {
    return {
      swiperHero: null,
    };
  },
  setup() {
    const editTemplate = computed(
      () => store.state["app-whitelabel-templates"].editTemplate
    );

    const heroSwiperConfig = computed(
      () => editTemplate.value.templateData.heroSwiper
    );

    return {
      heroSwiperConfig,
    };
  },
  methods: {
    nextSlideHero() {
      this.swiperHero.slideNext();
    },
    prevSlideHero() {
      this.swiperHero.slidePrev();
    },
  },
  mounted() {
    this.swiperHero = new Swiper(this.$refs.swiperHero, {
      slidesPerView: 1,
      speed: 800,
    });
  },
};
</script>

<template>
  <div ref="swiperHero" class="swiper gn-swiper-hero" style="height: fit-content;">
    <div @click="prevSlideHero" class="swiper-prev">
      <svg viewBox="0 0 32 32">
        <path
          d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
        ></path>
      </svg>
    </div>
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(image, index) in heroSwiperConfig.images"
        :key="index"
      >
        <img :src="image.src" alt="hero-swiper-image" />
      </div>
    </div>
    <div @click="nextSlideHero" class="swiper-next">
      <svg viewBox="0 0 32 32">
        <path
          d="M11.386 3l13 13-13 13-3.772-3.772 9.228-9.228-9.228-9.228z"
        ></path>
      </svg>
    </div>
  </div>
</template>

<style scoped lang="scss">
.gn-swiper-hero {

  .swiper {
    &-prev,
    &-next {
      position: absolute;
      top: 0;
      height: 100%;
      width: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
      opacity: 0.5;

      &:hover {
        opacity: 0.8;
      }

      svg {
        width: 30px;
        height: 30px;
        fill: #fff;
      }
    }

    &-prev {
      left: 0;

      svg {
        transform: rotate(180deg);
      }
    }

    &-next {
      right: 0;
    }

    &-slide {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }
}
</style>
