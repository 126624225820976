<template>
  <div class="gn-register">
    <b-button size="sm"  pill class="register-button" :style="buttonConfig">Singup</b-button>
  </div>
</template>
<script>
export default {
  props: ["config"],
  computed: {
    buttonConfig() {
      return {
        color: this.config.color,
        background: `linear-gradient(90deg, ${this.config.background_color_1} 0%, ${this.config.background_color_2} 100%)`,
        border: "none",
        textWrap: 'nowrap'
      };
    }
  },
};
</script>
<style scoped lang="scss">

.gn-register {

  .register-button {
    font-size: 0.6rem;
    padding: 0.35rem 0.8rem 0.4rem 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
  }

  button {
    .icon {
      transition: all 0.2s ease;
    }

    &:hover {
      filter: brightness(0.9);

      .icon {
        transform: translateX(1px) !important;
      }
    }
  }
}

.close-button {
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
}
</style>
