import Vue from "vue";

export default {
    name: "IFrame",
    props: {
        hoverEditComponent: false,
        showScroll: {  //control scrollbar visibility
            type: Boolean,
            default: true
        }
    }, // Prop to listen for changes

    data() {
        return {
            iApp: null, // Will hold the Vue instance rendered inside the iframe
            children: null, // Will hold the content (components) to be rendered inside the iframe
        };
    },

    render(h) {
        return h("iframe", {
            on: {
                load: this.renderChildren, // When the iframe is loaded, call the renderChildren method
            },
        });
    },

    beforeMount() {
        this.children = this.$slots.default; // Get the content to be rendered inside the iframe
    },

    beforeDestroy() {
        this.cleanupIframe();
    },

    methods: {
        renderChildren() {
            const self = this; // Store the context of the IFrame component
            this.$nextTick(() => {
                const iframeDocument = this.$el.contentDocument;
                const iframeWindow = this.$el.contentWindow; // Get the window object of the iframe

                this.setupIframeContent(iframeDocument); // Set up the content of the iframe
                if(!iframeDocument)return;
                this.loadScripts(iframeDocument, iframeWindow)
                    .then(() => {
                        const iApp = new Vue({
                            name: "iApp",
                            data: () => ({
                                children: this.children,
                                selectedElement: null,
                                iframeWindow, // Reference to iframeWindow
                            }),
                            render(h) {
                                return h(
                                    "div",
                                    {
                                        attrs: {
                                            dir: "auto",
                                        },
                                    },
                                    this.children
                                );
                            },
                            mounted() {
                                self.$emit('iframe-loaded');
                            },
                        });

                        iApp.$swiper = iframeWindow.Swiper; // Add Swiper to the Vue instance
                        iApp.$mount(iframeDocument.getElementById("appIframe")); // Mount the Vue instance to the iframe
                        self.iApp = iApp; // Store the Vue instance in the parent component
                    })
                    .catch((error) => {
                        console.log("error");
                        console.log(error);
                    });
            });
        },

        setupIframeContent(iframeDocument) {
            // Define styles to be loaded
            const styles = [
                {
                    href: "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.5/swiper-bundle.min.css",
                },
                {
                    href: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css",
                    crossOrigin: "anonymous",
                    integrity:
                        "sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N",
                },
                {
                    href: 'https://cdn.jsdelivr.net/npm/@tailwindcss/browser@4"'
                }
            ];

            if(!iframeDocument || !iframeDocument.body || !iframeDocument.head)
                return;

            // Clear current content of the iframe
            iframeDocument.body.innerHTML = "";

            // Add head to the iframe
            const headElement = iframeDocument.head;
            headElement.innerHTML = this.$parent.head;
            iframeDocument.documentElement.appendChild(headElement);

            // Add the provided styles
            styles.forEach((style) => {
                const linkElement = document.createElement("link");
                linkElement.rel = "stylesheet";
                linkElement.href = style.href;
                if (style.crossOrigin) {
                    linkElement.crossOrigin = style.crossOrigin;
                }
                if (style.integrity) {
                    linkElement.integrity = style.integrity;
                }
                headElement.appendChild(linkElement);
            });

            if (!this.showScroll) {
                iframeDocument.body.style.setProperty('overflow', 'hidden');
            }

            // Create and add the app container to the iframe
            const appElement = document.createElement("div");
            appElement.id = "appIframe";
            iframeDocument.body.appendChild(appElement);
        },

        loadScripts(iframeDocument, iframeWindow) {
            const scripts = [
                "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.5/swiper-bundle.min.js", // <== TODO remove if not used
                "https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js",
                "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/js/bootstrap.min.js",
            ];

            const loadScript = (src) => {
                return new Promise((resolve, reject) => {
                    const script = document.createElement("script");
                    script.src = src;
                    script.onload = () => {
                        resolve(src);
                    };
                    script.onerror = () => {
                        reject(new Error(`Script load error for ${src}`));
                    };
                    iframeDocument.body.appendChild(script);
                });
            };

            return Promise.all(scripts.map((src) => loadScript(src))).then(
                (loadedScripts) => {
                    console.log("All scripts loaded:", loadedScripts);
                    if (!iframeWindow.Swiper) {
                        throw new Error("Swiper failed to load");
                    }
                }
            );
        },

        cleanupIframe() {
            if (this.iApp) {
                // Destroy the Vue instance inside the iframe
                this.iApp.$destroy();

                // Clear references
                this.iApp = null;
            }

            // Clear the content of the iframe
            if (this.$el && this.$el.contentDocument) {
                this.$el.contentDocument.body.innerHTML = '';
                this.$el.contentDocument.head.innerHTML = '';
            }

            // Remove the iframe from the DOM
            if (this.$el && this.$el.parentNode) {
                this.$el.parentNode.removeChild(this.$el);
            }

            // Delete any other event listeners or references you created
        }

    },

}