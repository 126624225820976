<script>
import GanamosHeroSwiper
  from "@/views/components/whitelabel-templates/Ganamos/components/sections/GanamosHeroSwiper.vue";
import GanamosFeaturedSelection
  from "@/views/components/whitelabel-templates/Ganamos/components/sections/GanamosFeaturedSelection.vue";
import GanamosGamesPreview
  from "@/views/components/whitelabel-templates/Ganamos/components/sections/GanamosGamesPreview.vue";

export default {
  name: 'GanamosHomePage',
  components: {
    GanamosFeaturedSelection,
    GanamosHeroSwiper,
    GanamosGamesPreview
  },
};
</script>

<template>
  <div>
    <ganamos-hero-swiper/>
    <ganamos-featured-selection/>
    <ganamos-games-preview/>
  </div>
</template>

<style scoped lang="scss"></style>
