<template>
  <div class="gn-login">
    <b-button size="sm"  pill class="login-button" :style="loginButtonConfig"> Login </b-button>
  </div>
</template>

<script>
export default {
  name: "GanamosLogin",
  props: ["config"],
  computed: {
    loginButtonConfig() {
      return {
        color: this.config.color,
        background: `linear-gradient(90deg, ${this.config.background_color_1} 0%, ${this.config.background_color_2} 100%)`,
        border: "none",
        textWrap: 'nowrap'
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled-link {
  pointer-events: none;
  opacity: 0.5;
}

.gn-login {
  .login-button {
    font-size: 0.6rem;
    padding: 0.35rem 0.8rem 0.4rem 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
  }

  button {
    .icon {
      transition: all 0.2s ease;
    }

    &:hover {
      filter: brightness(0.9);

      .icon {
        transform: translateX(1px) !important;
      }
    }
  }
}
</style>
