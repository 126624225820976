<script>

import {computed} from "@vue/composition-api";
import store from "@/store";

export default {

  props: {
    game: {
      type: Object,
      default: () => { },
    },
  },

  setup() {
    const editTemplate = computed(
        () => store.state["app-whitelabel-templates"].editTemplate
    );

    return {
      editTemplate
    };
  },

  mounted() {
    this.usersOnline = Math.ceil(Math.random() * 100);
  },
};
</script>

<template>
  <a class="mb-3 text-white card" style="border: none">
    <div class="card-img">
      <b-img
        class="card-img-content"
        :src="game.src"
        :alt="game.name || 'game image'"
        :blank-src="editTemplate.logo" />
      <div class="card-img-play">
        <div class="card-img-play-title">{{ game.name }}</div>
        <div class="card-img-play-icon">
          <svg viewBox="0 0 32 32">
            <path fill="#ffffff"
              d="M23.011 14.463c1.055 0.753 1.055 2.321 0 3.075l-8.607 6.145c-1.25 0.893-2.986-0.001-2.986-1.537v-12.291c0-1.536 1.736-2.43 2.986-1.537l8.607 6.145z">
            </path>
          </svg>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="font-weight-bolder card-text">{{ game.name }}</div>
      <div class="pb-2 text-xs transform -translate-y-1 card-text">{{ game.brand }}</div>
    </div>
  </a>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins.scss";

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.card {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  transition: all 0.2s ease-in-out;
  background: none;

  &:hover {


    .card-img-content{
      transform: scale(1.03);
      filter: brightness(1.01) contrast(1.005) drop-shadow(0 0 15px var(--primary-color));
    }
  }

  .online {
    position: absolute;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0.375rem;
    right: 0.375rem;
    height: 1rem;
    font-size: 0.75rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.5rem;
    padding: 0 0.375rem;
    line-height: 0.75rem;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    z-index: 3;
  }

  &-img {
    position: relative;

    &-content {
      width: 100%;
      position: relative;
      aspect-ratio: 10 / 9;
      overflow: hidden;
      transition: 0.3s;
    }

    &-play {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(8px);
      display: flex;
      flex-direction: column;
      background-color: rgba(0, 0, 0, 0.6);
      opacity: 0;
      transition: var(--transition);
      padding: 10px;
      z-index: 5;

      &-title {
        color: white;
        font-weight: 700;
      }

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;

        svg {
          width: 50px;
          height: 50px;
        }
      }
    }
  }

  &-body {
    padding: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .btn {
    display: none;

    @include responsive(lg) {
      display: inline-block;
    }
  }

  &-info {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;

    @include responsive(lg) {
      display: block;
    }
  }

  &-text {
    max-width: 100%;
    min-width: 100%;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--text-color);
  }
}
</style>
