<script>
import store from "@/store";
import authStoreModule
  from "@/views/components/whitelabel-templates/Ganamos/config/PageEdit/AuthManagement/store/authStoreModule";
import {ref, onMounted, computed} from '@vue/composition-api';

export default {
  name: "GanamosLogin",
  props: {
    config: {
      type: Object,
      required: true,
    }
  },
  setup() {
    const AUTH_STORE = "auth-store";
    const gnLogin = ref(null);
    // Register module
    if (!store.hasModule(AUTH_STORE))
      store.registerModule(
          AUTH_STORE,
          authStoreModule
      );

    const showLoginDialog = computed(() => {
      return store.state[AUTH_STORE].showLoginAuthModal;
    });

    const handleHideDialog = () => {
      store.commit(`${AUTH_STORE}/HIDE_ALL`);
    };

    return {
      showLoginDialog,
      handleHideDialog,
    }
  },
  methods: {},
};
</script>

<template>
  <div class="ganamos-login" style="user-select: none">
    <div
        class="modal fade"
        :class="{ show: showLoginDialog, 'd-block': showLoginDialog }"
        tabindex="-1"
        id="ganamos-login-modal"
        aria-hidden="true">

      <div class="modal-dialog kb-auth-modal-dialog"
           style="border-radius: 0.25rem">
        <div class="modal-content kb-auth-modal"
             style="background-color: var(--background-color);
                    box-shadow: 0 0 30px 10px var(--placeholder-color);
                    border-radius: 5px;">
          <button
              @click="handleHideDialog"
              type="button"
              style="right: 10px;
                     text-shadow: none;"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="kb-auth-modal-left">
            <img
                alt="register_modal_image"
                class="login-left"
                :src="config.auth_modal_left_desktop_image"
            />
          </div>

          <b-form class="kb-auth-modal-right">
            <div class="kb-auth-modal-right-header form-group">
              <div style="padding-bottom: 1.5rem; font-weight: bold"
                   class="text-center text-md text-capitalize">
                Welcome Back
              </div>
            </div>

            <b-form-group label-for="username">
              <b-form-input
                  style="border-radius: 9999999px; border: none; background-color: linear-gradient(to bottom, color-mix(in srgb, var(--placeholder-color) 100%, transparent 100%) 0%, color-mix(in srgb, var(--placeholder-color) 10%, transparent 100%) 100%)"
                  id="username"
                  disabled
                  type="text"
                  placeholder="Username"
                  :aria-describedby="`username-feedback`"
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-input-group>
                <b-form-input
                    style="border-radius: 9999999px; border: none; background-color: linear-gradient(to bottom, color-mix(in srgb, var(--placeholder-color) 100%, transparent 100%) 0%, color-mix(in srgb, var(--placeholder-color) 10%, transparent 100%) 100%)"
                    placeholder="Password"
                    type="password">

                </b-form-input>
                <b-input-group-append style="position: absolute;
                                             right: 0;
                                             top: 0;
                                             height: 38px;">
                  <b-button class="login-show-password-button" style=" background: none;">
                    <b-icon :icon="'eye-fill'"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div class="d-flex items-center justify-content-between">
              <b-button
                  style="background: linear-gradient(to right,var(--placeholder-color) 0%, var(--primary-color) 100%);"
                  class="font-bold login-button"
                  type="submit">
                <span>Login</span>
              </b-button>

              <a
                  href="#"
                  class="text-decoration-none">
                Forgot your password?
              </a>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: showLoginDialog }"></div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins";

.kb-modal {
  position: fixed;
  z-index: 1000;
}

.kb-auth-modal {
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  border: none;


  .modal-content {
    box-shadow: 0 0 30px 10px var(--placeholder-color);
    color: var(--text-color);
  }

  @include responsive(sm) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 98dvh;
  }


  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #ffffff;
    text-shadow: none;
    width: 29px;
    transition: 0.4s;

    &:hover {
      color: #ffffff;
      transform: scale(1.1) rotate(-90deg);
    }
  }

  &-dialog {
    max-width: 100vw !important;
    background-color: #202227 !important;
    transition: var(--transition);
    transform: translateY(-10px) !important;
    opacity: 0;

    @include responsive(down-sm) {
      width: 100%;
      height: 100dvh;
      margin: 0;
    }

    @include responsive(sm) {
      border-radius: var(--radius);
      width: 800px !important;
    }
  }

  &-left {
    img {
      position: absolute;
      bottom: -10px;
      height: calc(100% + 40px);
      max-width: 100vw;
      left: -40px;
    }

    &-mobile {
      @include responsive(sm) {
        display: none;
      }
    }

    &-desktop {
      @include responsive(down-sm) {
        display: none;
      }
    }
  }

  &-right {
    padding: 30px;

    a {
      color: var(--primary-color);
      font-size: 0.6rem;
    }

    .login-show-password-button {
      background: none;
      border: none;

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    .login-button {

      color: var(--header-c) !important;
      border: none !important;
      border-radius: 99999px;

      &:hover {
        filter: brightness(0.9);
      }

      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    &-content {
      max-height: 50vh;
      padding-right: 20px;
      padding-top: 2px;
      padding-left: 2px;
    }

    &-header {
      color: white;
      font-weight: 600;
    }


    .invalid-feedback {
      margin-top: 8px;
      font-size: 0.7rem;
    }

    &-checks {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 0.8rem;
      padding-top: 0.4rem;

      a {
        color: white;
        cursor: pointer;
        font-weight: 600;

        &:hover {
          color: white;
          text-decoration: underline;
        }
      }

      .form-control {
        border-radius: 9999999px;
        border: none;
        background: linear-gradient(to bottom,
            color-mix(in srgb, var(--placeholder-color) 100%, transparent 100%) 0%,
            color-mix(in srgb, var(--placeholder-color) 10%, transparent 100%) 100%) !important;
        overflow: hidden;
      }

      .input-group-append {
        position: absolute;
        right: 0;
        top: 0;
        height: 38px;
      }
    }


    &-footer {
      display: flex;
      gap: 10px;
      color: white;

      a {
        color: var(--auth-modal-color) !important;
        cursor: pointer;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.modal {
  &.show {
    .kb-auth-modal-dialog {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.modal-backdrop {
  pointer-events: none;
  transition: none !important;

  &.show {
    pointer-events: auto;
  }
}

.kb-form-select {
  background-color: var(--setting-color-primary-text) !important;
  border: 2px solid var(--setting-color-primary-text) !important;
  color: var(--setting-color-primary-text);
}

.kb-backgorund-color {
  background-color: var(--setting-color-primary) !important;
  color: var(--setting-color-primary-text) !important;
  border: none;
}

</style>
