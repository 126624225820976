<template>
  <div class="gn-side-nav" :style="mainStyle">
    <button @click="showSideBar = !showSideBar" class="hamburger-button">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-menu"
      >
        <line x1="3" y1="12" x2="21" y2="12"></line>
        <line x1="3" y1="6" x2="21" y2="6"></line>
        <line x1="3" y1="18" x2="21" y2="18"></line>
      </svg>
    </button>

    <b-sidebar
        v-model="showSideBar"
        shadow
        bg-variant="none"
        text-variant="white"
        sidebar-class="sidebar-color"
        @change="handleSidebarChange"
    >
      <nav class="w-full">
        <ul class="flex flex-col w-full p-0 m-0">
          <li v-for="(link, i) in menu.links" :key="i" class="w-full link-cont">
            <a
                :class="[
                'link font-bold px-4 py-4 m-auto text-center whitespace-nowrap inline-block',
                `order-${link.position}`,
              ]"
                :title="link.title"
                :style="linkStyle"
            >
              <p>{{link.title}}</p>
            </a>
          </li>
        </ul>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>

export default {
  name: "GanamosSideNav",
  components: {
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSideBar: false,
      isMobile: false,
    };
  },
  computed: {
    mainStyle() {
      return {
        "--background-color": this.menu.background_color
            ? this.menu.background_color
            : "black",
        "--background-color-hover": this.menu.background_hover_color
            ? this.menu.background_hover_color
            : "",
        "--color": this.menu.color ? this.menu.color : "white",
        "--color-hover": this.menu.hover_color
            ? this.menu.hover_color
            : "black",
        "background-color": this.menu.background_color
            ? this.menu.background_color
            : "red",
        "background-image": this.menu.background_image
            ? `url('${this.menu.background_image}')`
            : "",
        "background-size": "100% !important",
        "background-repeat": "no-repeat !important",
      };
    },
    linkStyle() {
      return {
        fontSize: this.menu.font_size ? `${this.menu.font_size}px` : "1rem",
        height: "54px",
        textDecoration: "none",
        textAlign: "center",
        color: this.menu.color || "white",
      };
    },
  },
  methods: {
    handleSidebarChange(newState) {
      this.showSideBar = newState;
    },
  },
};
</script>

<style scoped>
.gn-side-nav {
  display: flex;

  @media (min-width: 1024px) {
    display: none;
  }
}

.link-cont {
  position: relative;
}

.link-cont::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: var(--color-hover);
  transition: width 0.2s;
}

.link-cont:hover::after {
  width: 100%;
}

.link-cont:hover {
  transition: background-color 0.5s;
  background-color: var(--background-color-hover);
}

.link-cont:nth-of-type(2n) {
  backdrop-filter: invert(0.08);
}
</style>
