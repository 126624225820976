<script>
import InlineSvg from "@/views/components/whitelabel-templates/common/InlineSvg.vue";
import {computed} from "@vue/composition-api";

export default {
  name: "GanamosMobileBar",
  components: {
    InlineSvg
  },
  props: {
    mobileBar: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const beforeSpecialLinks = computed(
        () => props.mobileBar.links.slice(0, 2)
    );
    const afterSpecialLinks = computed(
        () => props.mobileBar.links.slice(2)
    );

    return {
      beforeSpecialLinks,
      afterSpecialLinks
    }
  },

};
</script>

<template>
  <div style="left: 0; background: var(--mobile_bar-background);" class="ganamos-mobile-bar">
    <button
        v-for="(link, index) in beforeSpecialLinks"
        :key="index+'before'"
    >
      <span class="ganamos-mobile-bar-icon">
        <inline-svg :svg-data="link.svgData"/>
      </span>
      <span style="width: 100%"> {{ link.title }} </span>
      <img
          class="img-active"
          :src="mobileBar.active_link_src"
          alt="img-active"
      />
    </button>

    <button class="special active">
      <template v-if="mobileBar.special_link">
        <img
            style="left: 50%; transform: translate(-53%, 48px);"
            class="dog"
            alt="img-dog"
            :src="mobileBar.special_link.src"
        />
        <span style="width: 100%">
        {{ mobileBar.special_link.text }}
      </span>
      </template>
      <img
          class="img-active"
          :src="mobileBar.active_link_src"
          alt="img-active"
      />
    </button>

    <button
        v-for="(link, index) in afterSpecialLinks"
        :key="index+'after'"
    >
      <span class="ganamos-mobile-bar-icon">
        <inline-svg :svg-data="link.svgData"/>
      </span>

      <span>
        {{ link.title }}
      </span>

      <img
          class="img-active"
          :src="mobileBar.active_link_src"
          alt="img-active"
      />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins.scss";

.ganamos {
  &-mobile-bar {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--mobile_bar-background);
    height: 60px;
    z-index: 200;

    @include responsive(lg) {
      display: none;
    }

    button {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 10px;
      gap: 3px;
      background: none;
      border: none;
      height: 100%;
      color: var(--text-color);
      fill: var(--text-color);
      position: relative;
      font-size: 0.625rem;
      font-weight: 800;
      line-height: 0.625rem;
      overflow: visible;

      svg {
        height: 1.2rem;
        width: 1.2rem;
      }

      &.active {
        .img-active {
          opacity: 1;
        }
      }

      .img-active {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 200%;
        height: 100%;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        transition: 0.3s;
      }

      &.special {
        .dog {
          width: 8rem;
          height: 8rem;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translate(-53%, 48px);
          max-width: fit-content;
        }
      }
    }
  }
}
</style>
