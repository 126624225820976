<script>
export default {
  name: "GanamosFooter",
  props: {
    footer: {
      type: Object,
      required: true
    }
  },

  computed: {
    footerStyle() {
      return {
        "--primary-color": this.footer.primary_color,
        '--text-color': this.footer.color || "#fff",
        backgroundImage: this.footer.background_image
            ? `url('${this.footer.background_image}')`
            : "",
        backgroundColor: this.footer.background_color || "#000",
        color: this.footer.color || "#fff",
      };
    },
  },
}

</script>


<template>
  <div :style="footerStyle" class="ganamos-footer">
    <div class="ganamos-footer-right">
      <div
          v-for="(section, index) in footer.right.sections"
          :key="index"
          class="ganamos-footer-section">
        <!-- <div class="ganamos-footer-section-title">

        </div> -->
        <div
            v-for="(i, index) in section.items"
            :key="index"
            class="ganamos-footer-section-item">
          <span> {{i.content}} </span>
        </div>
      </div>
    </div>

    <div class="ganamos-footer-left">
      <div class="navbar-brand">
        <a href="">
          <img
              :src="footer.left.image"
              class="pure-img"
              style="width: 250px; max-width: 100%"
              alt="logo" />
        </a>
      </div>
      <p v-for="(i, index) in footer.left.items" :key="index">
       <span> {{i.content}} </span>
      </p>

    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins";

.ganamos-footer {
  background-color: #053d76;
  color: var(--text-color);
  padding: 50px 0 100px 0;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include responsive(lg) {
    padding: 80px 0 180px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }

  &-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;

    @include responsive(lg) {
      align-items: center;
    }

    p {
      margin: 0;
    }
  }

  &-right {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 0 20px;

    @include responsive(lg) {
      grid-template-columns: repeat(3, 1fr);
      padding: 0 0 0 20px;
    }
  }

  &-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &-title {
      font-weight: 700;
      font-size: 22px;
      position: relative;
      width: 100px;
      padding-bottom: 5px;
    }

    &-item {
      font-size: 14px;
      cursor: pointer;
      transition: color 0.3s;
      color: var(--text-color);
      text-decoration: none;
      position: relative;
      text-transform: uppercase;
      font-optical-sizing: 0.75rem;
      line-height: 1.2rem;
      font-weight: 700;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: var(--primary-color);
        transition: width 0.3s ease;
      }

      &:hover::after {
        width: 100%;
      }
    }
  }
}
</style>
