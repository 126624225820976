<script>

import GanamosHeader from "@/views/components/whitelabel-templates/Ganamos/components/common/GanamosHeader.vue";
import {computed, onMounted, ref, watch} from "@vue/composition-api";
import store from "@/store";
import gnStoreModule from "@/views/components/whitelabel-templates/Ganamos/store/gnStoreModule";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import {createCssVar} from "@/views/components/whitelabel-templates/common/utils/createCssVar";
import GanamosFooter from "@/views/components/whitelabel-templates/Ganamos/components/common/GanamosFooter.vue";
import GanamosResponsibleGaming
  from "@/views/components/whitelabel-templates/Ganamos/components/common/GanamosResponsibleGaming.vue";
import GanamosHomePage from "@/views/components/whitelabel-templates/Ganamos/views/GanamosHomePage.vue";
import UserProfile from "@/views/components/whitelabel-templates/common/UserProfile/UserProfile.vue";
import GanamosMobileBar from "@/views/components/whitelabel-templates/Ganamos/components/sections/GanamosMobileBar.vue";
import GanamosLogin from "@/views/components/whitelabel-templates/Ganamos/components/common/GanamosLogin.vue";
import GanamosRegister from "@/views/components/whitelabel-templates/Ganamos/components/common/GanamosRegister.vue";


const MODULE_GN_STORE_NAME = "gn-store";
const MODULE_STORE_NAME = "app-whitelabel-templates";

export default {
  name: "GanamosTemplate",
  components: {
    GanamosRegister,
    GanamosLogin,
    UserProfile,
    GanamosHomePage,
    GanamosFooter,
    GanamosHeader,
    GanamosResponsibleGaming,
    GanamosMobileBar
  },

  setup() {
    // Register modules
    if (!store.hasModule(MODULE_GN_STORE_NAME))
      store.registerModule(MODULE_GN_STORE_NAME, gnStoreModule);
    if (!store.hasModule(MODULE_STORE_NAME))
      store.registerModule(MODULE_STORE_NAME, whitelabelTemplatesModule);

    const el = ref(null);


    const loginConfig = computed(() => editTemplate.value.templateData.login);

    const registerConfig = computed(() => editTemplate.value.templateData.register);

    const editTemplate = computed(
        () => store.state["app-whitelabel-templates"].editTemplate
    );

    const mainColors = computed(
        () => editTemplate.value.templateData.main.colors
    );

    const userProfileColors = computed(
        () => editTemplate.value.templateData.user_profile.colors
    );

    const mobileBarConfig = computed(
        () => editTemplate.value.templateData.mobile_bar
    );

    onMounted(() => {
      setCssVar();
      //  store.commit("setVuetifyTheme", false);
    });

    const headerConfig = computed(() => editTemplate.value.templateData.header);
    const footerConfig = computed(() => editTemplate.value.templateData.footer);
    const menuConfig = computed(() => editTemplate.value.templateData.menu);
    const mainFont = computed(() => editTemplate.value.templateData.main.font);
    const responsibleGamingConfig = computed(() => editTemplate.value.templateData.responsible_gaming);


    const setCssVar = () => {
      if (!el.value) return;
      createCssVar(el.value, {
        "--primary-color": mainColors.value.primaryColor,
        "--header-bg": headerConfig.value.background_color,
        "--header-c": headerConfig.value.color || '#fff',
        "--body-font": mainFont.value.font,
        "--body-color": mainColors.value.bodyColor,
        "--background-color": mainColors.value.backgroundColor,
        "--placeholder-color": mainColors.value.placeHolderColor,
        "--secondary-color": mainColors.value.secondaryColor,
        "--secondary-alt-color": mainColors.value.secondaryAltColor,
        "--para-color": mainColors.value.paraColor,
        "--para-alt-color": mainColors.value.paraAltColor,
        "--section-color": mainColors.value.sectionColor,
        "--bs-white": mainColors.value.bsWhite,
        "--text-color": mainColors.value.textColor,
        "--setting-bg-primary": userProfileColors.value.bgPrimary,
        "--setting-bg-secondary": userProfileColors.value.bgSecondary,
        "--setting-color-primary": userProfileColors.value.colorPrimary,
        "--setting-color-primary-text": userProfileColors.value.colorPrimaryText,
        "--setting-color-secondary": userProfileColors.value.colorSecondary,
        "--mobile_bar-background": mobileBarConfig.value.gradient_background,
        "--header-light": headerConfig.value.light_color || '#fff'
      });
    };

    watch([mainColors, headerConfig, menuConfig, footerConfig, userProfileColors], setCssVar, {
      deep: true,
      immediate: true,
    });

    const preview = computed(() => store.state["gn-store"].preview);

    const backgroundImageStyle = computed(() => {
      const bgImage = editTemplate.value.templateData.main.backgroundImage
      let style = {}

      if (bgImage) {
        style = {
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat'
        }
      }

      return style
    })

    return {
      backgroundImageStyle,
      el,
      headerConfig,
      footerConfig,
      menuConfig,
      preview,
      responsibleGamingConfig,
      mobileBarConfig,
      loginConfig,
      registerConfig,
      mainColors
    }

  }


}
</script>

<template>
    <main id="ganamos-template" ref="el">

      <GanamosLogin :config="loginConfig"/>

      <!--    <GanamosRegister :config="registerConfig"/>-->

      <div class="bg-image" :style="backgroundImageStyle">
        <ganamos-header v-if="headerConfig" :header="headerConfig" :menu="menuConfig"/>

        <user-profile v-show="preview === 'profile'"/>


        <ganamos-home-page v-show="preview === 'general'"/>
      </div>

      <ganamos-footer v-if="footerConfig" :footer="footerConfig"/>


      <ganamos-responsible-gaming
          v-if="responsibleGamingConfig"
          :responsible-gaming="responsibleGamingConfig"
      />

      <ganamos-mobile-bar v-if="mobileBarConfig" :mobile-bar="mobileBarConfig"/>
    </main>
</template>

<style scoped lang="scss">

html {
  scroll-behavior: smooth;
}

#ganamos-template {
  font-family: var(--body-font), serif !important;
  background-color: var(--body-color);
  font-size: 16px;
  line-height: 30px;
  margin: 0;
  font-weight: 400;
  overflow-x: hidden;
  letter-spacing: 0 !important;
  padding: 50px 0 0 0;

  @media (min-width: 1024px) {
    padding-top: 0;
  }
}
</style>