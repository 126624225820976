export const BRAND_LIST = [
    {
        brandName: "NETGAMING",
        gamesPreview: [
            {src: "/img/games/vs20tweethouse.png", id: 1, name: "The Tweety House", brand: "PragmaticPlay"},
            {src: "/img/games/vs40wildwest.png", id: 2, name: "Wild West Gold", brand: "PragmaticPlay"},
            {src: "/img/games/vswaysdogs.png", id: 3, name: "The Dog House Megaways", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 4, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 5, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 6, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 7, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 8, name: "Sweet Bonanza", brand: "PragmaticPlay"},
        ],
    },
    {
        brandName: "NETGAMING1",
        gamesPreview: [
            {src: "/img/games/vs20tweethouse.png", id: 1, name: "The Tweety House", brand: "PragmaticPlay"},
            {src: "/img/games/vs40wildwest.png", id: 2, name: "Wild West Gold", brand: "PragmaticPlay"},
            {src: "/img/games/vswaysdogs.png", id: 3, name: "The Dog House Megaways", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 4, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 5, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 6, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 7, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 8, name: "Sweet Bonanza", brand: "PragmaticPlay"},
        ],
    },
    {
        brandName: "NETGAMING1",
        gamesPreview: [
            {src: "/img/games/vs20tweethouse.png", id: 1, name: "The Tweety House", brand: "PragmaticPlay"},
            {src: "/img/games/vs40wildwest.png", id: 2, name: "Wild West Gold", brand: "PragmaticPlay"},
            {src: "/img/games/vswaysdogs.png", id: 3, name: "The Dog House Megaways", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 4, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 5, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 6, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 7, name: "Sweet Bonanza", brand: "PragmaticPlay"},
            {src: "/img/games/vs20fruitsw.png", id: 8, name: "Sweet Bonanza", brand: "PragmaticPlay"},
        ],
    },
]