<script>

import GanamosLogin from '../modules/login/GanamosLogin.vue';
import GanamosNav from './GanamosNav.vue';
import GanamosRegister from '../modules/register/GanamosRegister.vue';
import GanamosSideNav from "@/views/components/whitelabel-templates/Ganamos/components/common/GanamosSideNav.vue";

export default {
  name: "GanamosHeader",
  components: {
    GanamosSideNav,
    GanamosRegister,
    GanamosNav,
    GanamosLogin
  },
  data() {
    return {
      isMenuOpen: false,
      allowRegistration: true,
      userData: false
    }

  },
  props: {
    header: {
      type: Object,
      required: true
    },
    menu: {
      type: Object,
      required: true
    },
    appNavKey: {
      type: String,
      default: ''
    }
  },

  computed: {
    showLogo() {
      return this.header.logo && !this.header.logo.hide;
    },
  },
}
</script>


<template>
  <header class="ganamos-header" style="::before{left: 0; transform: translate(-20%, -50%)};
                                        ::after{right: 0; transform: translate(20%, -50%)}">
    <div class="ganamos-header-left">
      <!-- burger -->
      <div class="d-flex items-center" style="gap: 0.5rem;">
        <ganamos-side-nav v-if="menu" :menu="menu"/>

        <a v-if="showLogo" class="logo">
          <img :src="header.logo.href" alt="header-logo"/>
        </a>

        <p v-else class="logo">
          LOGO
        </p>
      </div>
    </div>

    <div class="ganamos-header-links">
      <ganamos-nav v-if="menu" :menu="menu" :key="appNavKey"/>
    </div>

    <div class="ganamos-header-right">
      <div class="d-flex items-center" style="gap: 0.5rem;">
        <ganamos-register v-if="!header.auth.hide" :config="header.auth"/>
        <ganamos-login
            v-if="!header.auth.hide"
            :config="header.auth"
            ref="userLogin"
        />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "../../../common/assets/mixins.scss";

.ganamos-header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  color: var(--header-c);
  background-color: var(--header-bg);
  right: 0;
  animation: fadeInDown 0.2s ease-in-out;
  will-change: transform, opacity;
  position: fixed;

  @include responsive(lg) {
    padding: 0 30px;
  }

  &::before,
  &::after {
    display: block;
    position: absolute;
    width: 7rem;
    height: 50%;
    border-radius: 50%;
    -webkit-filter: blur(2rem);
    filter: blur(2rem);
    background-color: var(--header-light);
    top: 0;
    z-index: -1;

    @include responsive(lg) {
      content: "";
    }
  }

  &::before {
    left: 0;
    transform: translate(-20%, -50%);
  }

  &::after {
    right: 0;
    transform: translate(20%, -50%);
  }

  &-left {
    display: flex;
    align-items: center;
    gap: 50px;
    padding-right: 20px;

    .logo {
      height: 30px;
      display: block;

      img {
        height: 100%;
        max-width: max-content;
      }
    }
  }

  &-links {
    position: fixed;
    transform: translateX(-200%);
    transition: .5s;

    @media (min-width: 1024px) {
      position: static;
      width: 100%;
      height: auto;
      transform: translateX(0);
    }

  }

  &-right {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .header-auth {
    &-login {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 600;
      gap: 0.5rem;
    }

    &-separator {
      width: 1px;
      height: 0.8rem;
      background-color: white;
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
