<script>
import {computed} from "@vue/composition-api";
import store from "@/store";

export default {
  name: "GanamosFeaturedSelection",
  setup() {
    const editTemplate = computed(
        () => store.state["app-whitelabel-templates"].editTemplate
    );

    const featuredSelectionConfig = computed(
        () => editTemplate.value.templateData.featuredSelection
    );

    return {
      featuredSelectionConfig,
    };
  },
};
</script>


<template>
  <div class="featured-selection">
    <div class="grid-container">
      <div
          v-for="(item, index) in featuredSelectionConfig.items"
          :key="index"
          class="featured-item">
        <img style="max-width: 100%; height: auto;" :src="item.src" alt="featured-selection-image"/>
        <img style="left: 0; transform: translate(0, -50%);" :src="item.primary_src" alt="featured-selection-icon"
             class="featured-selection-icon"/>
        <div
            style="
            left: 50%;
            transform-origin: bottom center;
            transform: translate(-50%, 0);
            background: linear-gradient(90deg, var(--primary-color) 0%, var(--placeholder-color) 100%);"
            class="featured-selection-btn">
          Play
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../../common/assets/mixins";

.featured-selection {
  padding: 20px 10px;

  &-icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 128px;
    transform: translate(0, -50%);
    transition: 0.3s 0.1s;
    z-index: 1;
  }

  &-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 5px 20px;
    background: linear-gradient(90deg, var(--primary-color) 0%, var(--placeholder-color) 100%);
    color: var(--text-color);
    z-index: 1;
    border-radius: 99999px;
    font-size: 0.7rem;
    transition: 0.3s 0.25s;
    letter-spacing: 1px;
    font-weight: 600;
    transform-origin: bottom center;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @include responsive(lg) {
      grid-template-columns: repeat(4, 1fr);
    }

  }

  .featured-item {
    position: relative;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transform: scale(1.03);
      filter: brightness(1.01) contrast(1.01) drop-shadow(0 0 15px var(--primary-color));

      .featured-selection-icon {
        transform: translate(7%, -50%) rotate(10deg) scale(1.1);
        filter: brightness(1.01) contrast(1.01) drop-shadow(0 0 10px rgba(0, 0, 0, 0.6));
      }

      .featured-selection-btn {
        transform: translate(-50%, -20%) scale(1.3);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>