<script>
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/whitelabel-templates/store/templatesStoreModule";
import {computed, onBeforeMount, onMounted, ref} from "@vue/composition-api";
import GanamosTemplate from "@/views/components/whitelabel-templates/Ganamos/GanamosTemplate.vue";
import Iframe from "@/views/components/whitelabel-templates/Ganamos/Iframe.js";

export default {
  name: "GanamosCharger",
  components: {GanamosTemplate, Iframe},
  props: {
    size: String,
    hoverEditComponent: Boolean,
    customWidth: String,
    customHeight: String,
    reRender: {
      type: Number,
      default: 0,
    },
    showScroll: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const MODULE_STORE_NAME = "app-whitelabel-templates";

    if (!store.hasModule(MODULE_STORE_NAME)) {
      store.registerModule(MODULE_STORE_NAME, whitelabelTemplatesModule);
    }

    const loading = ref(false); // TODO set true
    const head = ref("");
    const defaultTemplateIframe = ref(null);
    const preview = ref(null);

    const scale = computed(
        () =>
            ({
              smartphone: "scale(.8)",
              tablet: "scale(.9)",
              square: "scale(.95)",
              monitor: "scale(1)",
            }[props.size])
    );

    const previewSize = computed(
        () =>
            ({
              smartphone: { width: "350px", height: "500px" },
              tablet: { width: "750px", height: "800px" },
              square: { width: "1024px", height: "800px" },
              monitor: { width: "100%", height: "100%" },
            }[props.size])
    );

    const iframeStyle = computed(() => ({
      ...previewSize.value,
      width: '100%',
      height: '100%',
      maxWidth: props.customWidth || previewSize.value.width,
      maxHeight: props.customHeight || previewSize.value.height,
      border: "none",
      transform: `${scale.value} translate(0,0)`,
    }));

    const handleIframeLoaded = () => {
      loading.value = false;
    };

    onBeforeMount(() => {
      head.value = `
      <head>
        ${document.head.innerHTML}
      </head>`;
    });

    onMounted(() => {
      store.commit(
          "app-whitelabel-templates/SET_IFRAME_CHARGER_REF",
          defaultTemplateIframe.value
      );
    });

    return {
      loading,
      head,
      defaultTemplateIframe,
      preview,
      iframeStyle,
      handleIframeLoaded,
    };
  },

}
</script>

<template>
  <div class="preview" ref="preview">
    <b-spinner
        v-if="loading"
        style="align-self: center; position: absolute; z-index: 1000"
    ></b-spinner>
    <Iframe
        v-show="!loading"
        :key="reRender"
        :show-scroll="showScroll"
        @iframe-loaded="handleIframeLoaded"
        ref="defaultTemplateIframe"
        :hover-edit-component="hoverEditComponent"
        :style="iframeStyle"
    >
      <ganamos-template/>
    </Iframe>
  </div>
</template>

<style scoped lang="scss">
.preview {
  width: 100%;
  overflow: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
}

.spinner {
  align-self: center;
  position: absolute;
  z-index: 1000;
}

.preview::-webkit-scrollbar {
  -webkit-appearance: none;
}
.preview::-webkit-scrollbar:vertical {
  width: 10px;
}
.preview::-webkit-scrollbar-button:increment,
.preview::-webkit-scrollbar-button {
  display: none;
}

.preview::-webkit-scrollbar:horizontal {
  height: 10px;
}

.preview::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.preview::-webkit-scrollbar-track {
  border-radius: 10px;
}
</style>