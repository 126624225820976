<script>
import {computed} from "@vue/composition-api";

export default {
  name: "GanamosResponsibleGaming",
  props: {
    responsibleGaming: {
      type: Object,
      required: true
    }
  },

  setup(props) {
    const rGamingStyle = computed(() => ({
      backgroundColor: props.responsibleGaming.background_color
    }))

    return {
      rGamingStyle
    }
  }
}
</script>

<template>
  <div class="responsible-gaming" :style="rGamingStyle">
    <img
        v-for="(item, index) in responsibleGaming.images"
        :key="index"
        :src="item.src"
        alt="rg-image"
    />
    <div class="responsible-gaming__text">
      <a>{{ responsibleGaming.title }}</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.responsible-gaming {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  gap: 10px;

  img {
    width: 25px;
    height: auto;
  }

  &__text {
    font-size: 0.65rem;
    color: white;
    font-family: var(--body-font),serif !important;
  }
}
</style>